import { AppLayout } from "./components/app-layout/app-layout";
import { Tips } from "./components/tips/tips";
import { CameraPermission } from "./components/camera-permission";
import { MicroPermission } from "./components/micro-permission";
import { RecordVideo } from "./components/record-video/record-video";
import { useState } from "react";

function App() {
  const [currentPage, setCurrentPage] = useState("tips");
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);

  const renderPage = () => {
    switch (currentPage) {
      case "camera-permission":
        return <CameraPermission setCurrentPage={setCurrentPage} setStream={setVideoStream} />;
      case "micro-permission":
        return <MicroPermission setCurrentPage={setCurrentPage} setAudioStream={setAudioStream}/>;
      case "record-video":
        return <RecordVideo stream={videoStream} audioStream={audioStream} />;
      default:
        return <Tips setCurrentPage={setCurrentPage}/>;
    }
  };

  return (
    <AppLayout>
      {renderPage()}
    </AppLayout>
  );
}

export default App;
