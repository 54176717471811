import { EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { lenses } from "../constants/lenses";
import { DEFAULT_ACTIVE_INDEX } from "./record-video/record-video";
import "./slider.css";
import { useEffect, useState } from "react";

export const LensesSlider = ({ setSelecetedLense }) => {
  const [styleLine, setStyleLine] = useState({
    position: 'absolute',
    width: '500dvw',
    height: '18px',
    background: 'linear-gradient(90deg, #ffd13e 22%, #37b1ce 47.77%, #604ea2 71%)',
    top: 'calc(50% - 9px)',
    left: "-50%",
  });


  useEffect(() => {
    const sliderTrack = document.querySelector('.swiper-wrapper');

    const observer = new MutationObserver((mutationsList, observer) => {
      for(const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          setStyleLine ({
            ...styleLine,
            transform: mutation.target.style.transform,
          });
        }
      }
    });

    observer.observe(sliderTrack, { attributes: true });
  }, [])

  return (
    <>
      <Swiper
        spaceBetween={15}
        slidesPerView={3}
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        initialSlide={DEFAULT_ACTIVE_INDEX}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows: false,
        }}
        slideToClickedSlide={true}
        modules={[EffectCoverflow]}
        onActiveIndexChange={(swiper) => setSelecetedLense(swiper.activeIndex)}
        style={{ pointerEvents: "none" }}
      >
        {lenses.map((lense, index) => (
          <SwiperSlide
            key={lense.en}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", pointerEvents: "none" }}
          >
            <Image lense={lense} index={index} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div style={styleLine} />
    </>
  );
};

const Image = ({ lense }) => {
  return (
    <img
      src={lense.lense}
      style={{
        display: "block",
        width: "100%",
        maxWidth: "150px",
        position: "relative",
        zIndex: 150,
        pointerEvents: "all",
      }}
    />
  );
};
