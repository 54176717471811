import { Button } from "../button/button";
import styles from "./styles.module.css";

export const ContentLayout = ({ title, children, action, onClick, animate }) => {
  return (
    <div className={`${styles.layout} ${animate && styles.animate}`}>
      <h2 className={styles.title}>{title}</h2>
      {children}
      <div className={styles.action}>
        <Button action={action} onClick={onClick} />
      </div>
    </div>
  );
};
