export const Logo = () => {
  return (
    <svg width="79" height="22" viewBox="0 0 79 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M77.9649 10.2151C77.8015 9.48775 77.4807 8.80506 77.0249 8.2151C76.5766 7.62092 75.9941 7.14119 75.3249 6.8151C74.533 6.43641 73.6624 6.25133 72.7849 6.2751C72.2845 6.26879 71.7858 6.33617 71.3049 6.4751C70.8606 6.60985 70.4316 6.79084 70.0249 7.0151C69.6483 7.23138 69.3047 7.50081 69.0049 7.8151L68.6849 8.1751V6.6951H63.6249V21.4351H68.8649V13.4351C68.8621 13.0704 68.9093 12.707 69.0049 12.3551C69.1024 12.0273 69.2509 11.7168 69.4449 11.4351C69.635 11.1895 69.8732 10.9853 70.1449 10.8351C70.4585 10.6924 70.8007 10.624 71.1449 10.6351C71.4639 10.619 71.7814 10.688 72.0649 10.8351C72.2984 10.9682 72.4918 11.1616 72.6249 11.3951C72.7695 11.6713 72.8706 11.9681 72.9249 12.2751C72.9564 12.6478 72.9564 13.0224 72.9249 13.3951V21.3951H78.1449V12.3951C78.1404 11.6598 78.0464 10.9277 77.8649 10.2151"
          fill="#003595"/>
        <path
          d="M53.8049 6.25513C52.2535 6.25513 50.7369 6.71545 49.4472 7.57781C48.1575 8.44016 47.1526 9.66579 46.5598 11.0995C45.967 12.5333 45.8129 14.1107 46.1171 15.632C46.4212 17.1534 47.1699 18.5503 48.2683 19.6459C49.3668 20.7416 50.7656 21.4867 52.2877 21.7869C53.8099 22.0872 55.3868 21.929 56.8191 21.3326C58.2513 20.7361 59.4743 19.7281 60.3334 18.4362C61.1925 17.1443 61.6489 15.6266 61.6449 14.0751C61.6396 11.9993 60.8113 10.0103 59.3416 8.54433C57.8719 7.07837 55.8808 6.25512 53.8049 6.25513ZM56.9249 14.0751C56.9289 14.6978 56.7476 15.3077 56.4041 15.8271C56.0606 16.3465 55.5704 16.7521 54.9958 16.9922C54.4213 17.2324 53.7883 17.2962 53.1773 17.1757C52.5664 17.0552 52.005 16.7557 51.5647 16.3154C51.1244 15.875 50.8249 15.3137 50.7044 14.7028C50.5839 14.0918 50.6477 13.4588 50.8879 12.8842C51.128 12.3097 51.5335 11.8195 52.053 11.476C52.5724 11.1325 53.1822 10.9512 53.8049 10.9551C54.6324 10.9551 55.426 11.2838 56.0111 11.869C56.5962 12.4541 56.9249 13.2477 56.9249 14.0751Z"
          fill="#003595"/>
        <path
          d="M39.585 17.2151C39.0394 17.2186 38.5024 17.0798 38.0267 16.8125C37.5511 16.5453 37.1534 16.1587 36.8726 15.6909C36.5919 15.2231 36.4379 14.6902 36.4258 14.1448C36.4137 13.5993 36.5439 13.0601 36.8036 12.5804C37.0634 12.1006 37.4436 11.6967 37.9069 11.4086C38.3702 11.1205 38.9006 10.9581 39.4458 10.9374C39.991 10.9167 40.5321 11.0384 41.016 11.2905C41.4998 11.5426 41.9096 11.9164 42.205 12.3751L45.325 8.69508C44.2512 7.54484 42.8561 6.74434 41.3212 6.39769C39.7863 6.05104 38.1826 6.17428 36.7187 6.75139C35.2548 7.3285 33.9984 8.33277 33.113 9.63358C32.2276 10.9344 31.7541 12.4715 31.7541 14.0451C31.7541 15.6186 32.2276 17.1558 33.113 18.4566C33.9984 19.7574 35.2548 20.7617 36.7187 21.3388C38.1826 21.9159 39.7863 22.0391 41.3212 21.6925C42.8561 21.3458 44.2512 20.5453 45.325 19.3951L42.245 15.7351C41.9621 16.1858 41.5699 16.5578 41.1049 16.8165C40.6399 17.0752 40.1171 17.2123 39.585 17.2151Z"
          fill="#003595"/>
        <path
          d="M13.945 13.0551H9.54496L11.765 7.05507L13.945 13.0551ZM9.32496 0.695068L0.584961 21.4551H6.46496L7.96496 17.4551H15.485L17.065 21.4551H23.065L14.405 0.695068H9.32496Z"
          fill="#003595"/>
        <path d="M29.765 0.695068H24.485V21.4551H29.765V0.695068Z" fill="#003595"/>
      </g>
      <defs>
        <clipPath id="clip0_4006_2297">
          <rect width="77.66" height="21.22" fill="white" transform="translate(0.584961 0.695068)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
