import styles from "./styles.module.css";
import "../../ar-viewer/style.css";
import { useEffect, useRef, useState } from "react";
import { initAR } from "../../ar-viewer/alcon-ar-lenses";
import * as Sentry from "@sentry/browser";

export const Camera = ({ background, lense, stream, setArCanvas, setLoading, result, isStartRecord }) => {
  const app = useRef(null);
  const [ar, setAR] = useState(null);
  const progressRef = useRef(null);
  const videoPreview = useRef(null);
  const videoPlayButton = useRef(null);


  // init AR
  useEffect(() => {
    if (window.arModule) {
      const arInstance = window.arModule
      setAR(arInstance);
      setArCanvas(arInstance.canvas);
      if (lense)
        arInstance.setLense(lense);

      videoPreview.current = document.querySelector(`video.preview`);
      videoPlayButton.current = document.querySelector(`.${styles['video-preview-hidden']}`)
    }
    else {
      const arInstance = initAR(app.current, {
        bilateralConf: {
          msize: 4,
        },
        onFatalError: (e) => {
          console.error(e)
          Sentry.captureException(e)
          if (window.Telegram?.WebApp?.initData) {
            window.Telegram.WebApp.showAlert(`Возникла ошибка. Свяжитесь с технической поддержкой.`)
            window.Telegram.WebApp.close()
            return
          } else {
            alert(`Возникла ошибка. Свяжитесь с технической поддержкой.`)
            // TODO: а дальше что?
            return
          }
        }
      });
      window.arModule = arInstance
      setAR(arInstance);
      setArCanvas(arInstance.canvas);
      if (lense)
        arInstance.setLense(lense);

      if (!videoPreview.current) {
        const video = document.createElement("video");
        arInstance.canvas.parentElement.append(video);
        video.classList.add("preview");
        video.playsInline = 'playsinline';
        video.loop = 'loop';
        video.autoplay = 'autoplay';

        const button = document.createElement('div')
        button.classList.add(styles['video-play-button'])
        video.parentElement?.appendChild(button)

        video.classList.add(styles['video-preview-hidden'])
        button.classList.add(styles['video-preview-hidden'])
        video.addEventListener('click', () => video.play())
        video.addEventListener('play', () => {
          button.classList.add(styles['video-preview-hidden'])
          video.classList.remove(styles['video-preview-hidden'])
        })

        videoPreview.current = video;
        videoPlayButton.current = button
      }

      try {
        arInstance.start(stream).then(() => setLoading(false));
      } catch (error) {
        console.error("Error initializing AR:", error);
      }
    }

  }, [stream, setArCanvas, setLoading]);

  // change lense
  useEffect(() => {

    ar?.setLense(lense);

  }, [lense])

  // preview
  useEffect(() => {



    if (result && ar) {

      if (ar.pause)
        ar.pause()

      try {
        videoPreview.current.src = URL.createObjectURL(result);
        videoPreview.current.load()
        setTimeout(createPlayButtonIfNotPlaying, 250) // old ios fix
      }
      catch (ex) {
        console.warn('failed to load video', ex)
      }
      finally {
        URL.revokeObjectURL(result)
      }

    } else if (ar) {

      if (ar.unpause)
        ar.unpause()

      if (videoPreview.current) {
        videoPreview.current.classList?.add(styles['video-preview-hidden'])
        videoPlayButton.current?.classList?.add(styles['video-preview-hidden'])
        videoPreview.current.src = '';
      }

    }

  }, [result])

  useEffect(() => {

    const pause = () => {
      if (ar?.pause)
        ar.pause()

      if (result) {
        videoPreview.current?.pause()
        videoPlayButton.current?.classList?.remove(styles['video-preview-hidden'])
      }
    }

    const unpause = () => {
      if (!result) {
        if (ar?.unpause)
          ar.unpause()
      }
    }

    window.addEventListener('blur', pause)
    window.addEventListener('focus', unpause)

    return () => {
      window.removeEventListener('blur', pause)
      window.removeEventListener('focus', unpause)
    }

  }, [])

  // recording
  useEffect(() => {
    if (!isStartRecord) {
      progressRef.current.style.background = `conic-gradient(white 0deg, transparent 0deg)`;
      return;
    }

    const updateRate = 100;
    const animationSeconds = 60;
    const startTime = performance.now()

    const interval = setInterval(() => {
      const elapsedSeconds = (performance.now() - startTime) / 1000
      const progress = elapsedSeconds / animationSeconds

      progressRef.current.style.background = `conic-gradient(white ${Math.min(progress, 1) * 360
        }deg, transparent 0deg)`;

      if (progress >= 1) {
        clearInterval(interval);
      }
    }, updateRate);

    return () => clearInterval(interval);
  }, [isStartRecord]);


  function createPlayButtonIfNotPlaying() {
    const video = videoPreview.current
    if (video && result && video.paused) {
      videoPlayButton.current?.classList?.remove(styles['video-preview-hidden'])
      videoPreview.current?.classList?.remove(styles['video-preview-hidden'])
    }
  }

  return (
    <div className={styles.wrapper}>
      <div ref={app} className={styles.camera}>
      </div>
      <div className={styles.background} style={{ background: isStartRecord ? background : null }} />
      <div className={styles.progress} ref={progressRef} />
    </div>
  );
};


