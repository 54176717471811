import amethyst from "../assets/lenses_2/amethyst.png";
import blue from "../assets/lenses_2/blue.png";
import brilliant_blue from "../assets/lenses_2/brilliant_blue.png";
import brown from "../assets/lenses_2/brown.png";
import gem_green from "../assets/lenses_2/gem_green.png";
import green from "../assets/lenses_2/green.png";
import honey from "../assets/lenses_2/honey.png";
import sterling_gray from "../assets/lenses_2/sterling_gray.png";
import true_sapphire from "../assets/lenses_2/true_sapphire.png";
import turquoise from "../assets/lenses_2/turquoise.png";

export const lenses = [
  {
    lense: honey,
    ru: "Медовый",
    en: "Honey",
    background: "linear-gradient(#F7C278, #6D472B) border-box",
  },
  {
    lense: brilliant_blue,
    ru: "Бриллиантовый синий",
    en: "Brilliant Blue",
    background: "linear-gradient(#6EC3FF, #09559C) border-box",
  },
  {
    lense: gem_green,
    ru: "Изумрудно-зеленый",
    en: "Gem Green",
    background: "linear-gradient(#CDDB5D, #5E8C43) border-box",
  },
  {
    lense: sterling_gray,
    ru: "Серебристый Серый",
    en: "Sterling Grey",
    background: "linear-gradient(#9BB4CB, #5183A9) border-box",
  },
  { lense: amethyst, ru: "Аметист", en: "Amethyst", background: "linear-gradient(#B77CB5, #8667A4) border-box" },
  { lense: turquoise, ru: "Бирюзовый", en: "Turquoise", background: "linear-gradient(#57BDA1, #288A70) border-box" },
  {
    lense: true_sapphire,
    ru: "Сапфир",
    en: "True Sapphire",
    background: "linear-gradient(#448CD0, #4173BE) border-box",
  },
  { lense: brown, ru: "Карий", en: "Brown", background: "linear-gradient(#B67154, #A06747) border-box" },
  { lense: blue, ru: "Синий", en: "Blue", background: "linear-gradient(#6894D9, #68A3D9) border-box" },
  { lense: green, ru: "Зеленый", en: "Green", background: "linear-gradient(#93E19D, #4B834C) border-box" },
];
