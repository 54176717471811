import styles from "./styles.module.css";
import patternTopImg from "../../assets/pattern-top.png";
import patternBottomImg from "../../assets/pattern-bottom.png";
import {Logo} from "./logo.jsx";

export const AppLayout = ({children}) => {
  return (
    <main className={styles.layout}>
      <img src={patternTopImg} className={styles.patternTop}/>
      <header className={styles.header}>
        <Logo/>
      </header>
      {children}
      <img src={patternBottomImg} className={styles.patternBottom}/>
    </main>
  );
};
