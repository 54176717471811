import { ContentLayout } from "./content-layout/content-layout";
import * as Sentry from "@sentry/browser";

export const MicroPermission = ({ setCurrentPage, setAudioStream }) => {
  const handleClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);
    } catch (error) {
      console.error(error)
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
      switch (error.name) {
        case 'NotAllowedError':
        case 'NotFoundError':
        case 'SecurityError':
          console.warn('No audio for user: ', error.name)
          break
        case 'AbortError':
        case 'NotReadableError':
        default:
          Sentry.captureMessage(error.name, 'warning')
          if (window.Telegram?.WebApp?.initData) {
            window.Telegram.WebApp.showAlert(`Ошибка микрофона: ${error.name}. Проверьте, что микрофон не используется другими приложениями.`)
            window.Telegram.WebApp.close()
          } else {
            alert(`Ошибка микрофона: ${error.name}. Проверьте, что микрофон не используется другими приложениями.`)
          }
          throw error // let sentry have it
      }
    }

    setCurrentPage("record-video");
  };

  return (
    <ContentLayout title="Разрешите доступ к микрофону" action="Разрешить" onClick={handleClick} animate>
      <p style={{ textAlign: "center", marginBottom: "18px" }}>
        Это нужно, чтобы записать видео для друзей с&nbsp;понравившимся цветом линз{" "}
        <span style={{ fontWeight: 700, color: "#003595", textWrap: 'nowrap', whiteSpace: 'nowrap' }}>Alcon Air Optix Colors</span>
      </p>
    </ContentLayout>
  );
};
