import { ContentLayout } from "./content-layout/content-layout";
import { getMobileOperatingSystem } from "../scripts/helpers"

export const CameraPermission = ({ setCurrentPage, setStream }) => {
  const handleClick = async () => {
    try {
      const os = getMobileOperatingSystem()
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { exact: os == "Android" ? 400 : 500 },
          height: { exact: os == "Android" ? 400 : 500 },
        },
      });
      setStream(stream);
    } catch (error) {
      console.error(error)
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
      switch (error.name) {
        case 'NotAllowedError':
        case 'NotFoundError':
        case 'SecurityError':
          if (window.Telegram?.WebApp?.initData) {
            window.Telegram.WebApp.showAlert(`Для работы приложения необходим доступ к камере.`)
            window.Telegram.WebApp.close()
            return
          } else {
            alert(`Для работы приложения необходим доступ к камере.`)
            // TODO: а дальше что?
            return
          }
        case 'AbortError':
        case 'NotReadableError':
        default:
          if (window.Telegram?.WebApp?.initData) {
            window.Telegram.WebApp.showAlert(`Ошибка камеры: ${error.name}. Проверьте, что камера не используется другими приложениями.`)
            window.Telegram.WebApp.close()
          } else {
            alert(`Ошибка камеры: ${error.name}. Проверьте, что камера не используется другими приложениями.`)
          }
          throw error // let sentry have it
      }
    }

    setCurrentPage("micro-permission");
  };

  return (
    <ContentLayout title="Разрешите доступ к камере" action="Разрешить" onClick={handleClick} animate>
      <p style={{ textAlign: "center", marginBottom: "18px" }}>
        Это нужно для того, чтобы подобрать&nbsp;идеальный цвет линз{" "}
        <span style={{ fontWeight: 700, color: "#003595", textWrap: 'nowrap', whiteSpace: 'nowrap' }}>Alcon&nbsp;Air&nbsp;Optix&nbsp;Colors</span>, который подчеркнет вашу
        индивидуальность
      </p>
    </ContentLayout>
  );
};
