import { ContentLayout } from "../content-layout/content-layout";
import styles from "./styles.module.css";

const tips = [
  "Разрешите доступ к\u00A0камере вашего телефона",
  "Разрешите доступ к\u00A0микрофону вашего телефона",
  "Поправьте освещение, чтобы получить наилучший результат примерки",
  "Выберите цвет линз, который хотите примерить. С\u00A0помощью свайпа влево-вправо переключайтесь между разными цветами, чтобы выбрать идеальный оттенок!",
];

export const Tips = ({setCurrentPage}) => {
  const handleClick = () => {
    setCurrentPage('camera-permission')
  };

  return (
    <ContentLayout title="Как примерить линзы?" action="Подобрать оттенок!" onClick={handleClick}>
      <ul className={styles.ul}>
        {tips.map((tip, index) => (
          <li key={index} className={styles.li}>
            <span>{index + 1}</span>
            <p>{tip}</p>
          </li>
        ))}
      </ul>
    </ContentLayout>
  );
};
