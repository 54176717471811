import { useState, useEffect, useRef } from "react";
import { Camera } from "../camera/camera";
import { LensesSlider } from "../lenses-slider";
import { lenses } from "../../constants/lenses";
import { Button } from "../button/button";
import styles from "./styles.module.css";
import * as Sentry from "@sentry/browser";
import { getMobileOperatingSystem } from "../../scripts/helpers";

export const DEFAULT_ACTIVE_INDEX = 4;

export const RecordVideo = ({ stream, audioStream }) => {
  const [isLoading, setLoading] = useState(true);
  const [isUploading, setUploading] = useState(false);
  const [selectedLense, setSelecetedLense] = useState(DEFAULT_ACTIVE_INDEX);
  const [isStartRecord, setStartRecord] = useState(false);
  const [isStopRecord, setStopRecord] = useState(false);
  const [arCanvas, setArCanvas] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [result, setResult] = useState(null);
  const [recordStartTime, setRecordStartTime] = useState(0);

  const recorderTimeout = useRef(null)

  useEffect(() => {
    if (recordStartTime !== 0) {
      recorderTimeout.current = setTimeout(() => {
        onStopRecord();
      }, 60 * 1000);
    }
  }, [recordStartTime]); // Effect re-runs whenever countdown changes

  const onStartRecord = () => {
    clearTimeout(recorderTimeout.current)

    setStartRecord(true);
    setRecordStartTime(Date.now());

    setRecorder(
      startVideoRecording(arCanvas, audioStream, (event) => {
        setRecordStartTime(0);
        const recordedData = event.data;
        const file = new Blob([recordedData], { type: "video/mp4" });
        setResult(file);
      })
    );
  };

  const onStopRecord = () => {
    clearTimeout(recorderTimeout.current)

    setRecordStartTime(0);
    recorder?.stop();
    setRecorder(null);
    setStartRecord(false);
    setStopRecord(true);
  };

  const onStartReRecord = () => {
    const os = getMobileOperatingSystem()
    if (os == 'Android') {
      window.location.reload();
      return;
    } else {
      URL.revokeObjectURL(result);
      setResult(null);
      setStartRecord(false);
      setStopRecord(false);
    }
  };

  const onSendVideo = () => {
    if (!result) {
      console.error("nothing to send");
      return;
    }

    if (window.Telegram?.WebApp?.initData) {
      const formData = new FormData();
      formData.append("name", `video.mp4`);
      formData.append("blob", result, "video.mp4");

      fetch(import.meta.env.VITE_UPLOAD_VIDEO_URL, {
        method: "POST",
        body: formData,
        headers: window.Telegram
          ? {
            Authorization: "TG " + window.Telegram.WebApp.initData,
          }
          : {},
      })
        .then(async (response) => {
          const { ok, eta, position } = await response.json();
          if (!ok) throw new Error("Error from backend");
          window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");
          window.Telegram.WebApp.showAlert(
            position === 0
              ? `Видео отправлено в переписку с ботом`
              : `Вы добавлены в очередь на отправку видео, ваша позиция в очереди №${position}, примерное время ожидания ${eta}`
          );
          window.Telegram.WebApp.close();
        })
        .catch((e) => {
          console.error(e)
          Sentry.captureException(e)
          window.Telegram.WebApp.HapticFeedback.notificationOccurred("error");
          window.Telegram.WebApp.showPopup({
            title: "Произошла ошибка",
            message:
              "При загрузке видео произошла ошибка, вы можете попробовать отправить видео снова, либо перезаписать его",
          });
        })
        .finally(() => {
          setUploading(false);
        });

      setUploading(true);
    } else {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(result);
      a.download = "video-recording.mp4";
      a.click();
      URL.revokeObjectURL(result);
    }

    setResult(null);
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <span className={styles.loader}></span>
        </div>
      )}
      <div className={styles.wrapper} style={{ visibility: isLoading ? "hidden" : "visible" }}>
        <Camera
          background={lenses[selectedLense].background}
          lense={lenses[selectedLense].en}
          stream={stream}
          setArCanvas={setArCanvas}
          setLoading={setLoading}
          result={result}
          isStartRecord={isStartRecord}
        />
        <div className={styles.slider} style={{ visibility: !isStopRecord && !isLoading ? "visible" : "hidden" }}>
          <LensesSlider setSelecetedLense={setSelecetedLense} />
        </div>
        <div className={styles.activeLense} style={{ visibility: !isStopRecord && !isLoading ? "visible" : "hidden" }}>
          <p className={styles.ru}>{lenses[selectedLense].ru}</p>
          <p className={styles.en}>{lenses[selectedLense].en}</p>
        </div>
      </div>
      {isLoading ? (
        <></>
      ) : isUploading ? (
        <div className={`${styles.actions} ${styles.loaderContainerSmall}`}>
          <span className={styles.loader}></span>
        </div>
      ) : isStopRecord ? (
        <div className={styles.actions} style={{ visibility: isLoading ? "hidden" : "visible" }}>
          <p>Ваше видео записано. Отправить в&nbsp;переписку с&nbsp;чат-ботом?</p>
          <div className={styles.actionsButtons}>
            <Button action="Отправить" onClick={onSendVideo} />
            <Button action="Перезаписать" onClick={onStartReRecord} />
          </div>
        </div>
      ) : (
        <div
          className={styles.button}
          style={{
            visibility: isLoading ? "hidden" : "visible",
            transform: isStartRecord ? "translateX(-50%) scale(1.075) translateY(2px)" : "translateX(-50%)",
          }}
        >
          <button
            className={isStartRecord ? styles.stop : styles.start}
            onClick={isStartRecord ? onStopRecord : onStartRecord}
          />
          {isStartRecord && <span className={styles.circle} />}
        </div>
      )}
    </>
  );
};

/**
 *
 * @param {HTMLCanvasElement} canvasElement
 * @param {MediaStream} audioStream
 * @param {(ev: BlobEvent) => void} onComplete
 *
 * @returns {MediaRecorder}
 */
function startVideoRecording(canvasElement, audioStream, onComplete) {
  // Next, we need to capture the canvas as a media stream using the canvas.captureStream() method
  const canvasStream = canvasElement.captureStream();
  if (audioStream) {
    const audio = audioStream.getAudioTracks()[0];
    canvasStream.addTrack(audio);
  }

  const mediaRecorder = new MediaRecorder(canvasStream);
  mediaRecorder.start();

  mediaRecorder.onerror = (err) => {
    console.error(err);
  };

  // The recorded data is available in the mediaRecorder.ondataavailable event
  mediaRecorder.ondataavailable = onComplete;

  return mediaRecorder;
}
